@import './icons/style.css';
/* reset default browser styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

ul, ol {
    list-style: none;
}

a {
    text-decoration: none;
    color: inherit;
}

.container {
    width: calc(min(100%, 1280px) - 64px);
    margin: 0 auto;
}