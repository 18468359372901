@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?lnyl3c');
  src:  url('fonts/icomoon.eot?lnyl3c#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?lnyl3c') format('truetype'),
    url('fonts/icomoon.woff?lnyl3c') format('woff'),
    url('fonts/icomoon.svg?lnyl3c#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-search:before {
  content: "\e96f";
  color: #616161;
}
.icon-BookSearch:before {
  content: "\e96c";
}
.icon-News1:before {
  content: "\e96d";
}
.icon-Document:before {
  content: "\e96e";
}
.icon-Home:before {
  content: "\e96b";
  color: #fff;
}
.icon-Cart .path1:before {
  content: "\e904";
  color: rgb(0, 0, 0);
}
.icon-Cart .path2:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cart .path3:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cart .path4:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cart .path5:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(66, 66, 66);
}
.icon-Doctor .path1:before {
  content: "\e909";
  color: rgb(0, 0, 0);
}
.icon-Doctor .path2:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(66, 66, 66);
}
.icon-News .path1:before {
  content: "\e90b";
  color: rgb(0, 0, 0);
}
.icon-News .path2:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-News .path3:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-News .path4:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-News .path5:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(66, 66, 66);
}
.icon-GlobeLocation .path1:before {
  content: "\e910";
  color: rgb(0, 0, 0);
}
.icon-GlobeLocation .path2:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-GlobeLocation .path3:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-GlobeLocation .path4:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-GlobeLocation .path5:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(66, 66, 66);
}
.icon-Union .path1:before {
  content: "\e915";
  color: rgb(0, 0, 0);
}
.icon-Union .path2:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Union .path3:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Union .path4:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Union .path5:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(66, 66, 66);
}
.icon-Money .path1:before {
  content: "\e91a";
  color: rgb(0, 0, 0);
}
.icon-Money .path2:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Money .path3:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Money .path4:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Money .path5:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(66, 66, 66);
}
.icon-Color .path1:before {
  content: "\e91f";
  color: rgb(0, 0, 0);
}
.icon-Color .path2:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Color .path3:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Color .path4:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Color .path5:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(66, 66, 66);
}
.icon-B2BCase .path1:before {
  content: "\e924";
  color: rgb(0, 0, 0);
}
.icon-B2BCase .path2:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B2BCase .path3:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B2BCase .path4:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B2BCase .path5:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(66, 66, 66);
}
.icon-B2BCase .path6:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B2BCase .path7:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B2BCase .path8:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B2BCase .path9:before {
  content: "\e92c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B2BCase .path10:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(66, 66, 66);
}
.icon-Games .path1:before {
  content: "\e92e";
  color: rgb(0, 0, 0);
}
.icon-Games .path2:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Games .path3:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Games .path4:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Games .path5:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(66, 66, 66);
}
.icon-B .path1:before {
  content: "\e933";
  color: rgb(0, 0, 0);
}
.icon-B .path2:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path3:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path4:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path5:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(66, 66, 66);
}
.icon-B .path6:before {
  content: "\e938";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path7:before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path8:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path9:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path10:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(66, 66, 66);
}
.icon-B .path11:before {
  content: "\e93d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path12:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path13:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path14:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path15:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(66, 66, 66);
}
.icon-B .path16:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path17:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path18:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path19:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path20:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(66, 66, 66);
}
.icon-B .path21:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path22:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path23:before {
  content: "\e949";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path24:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path25:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(66, 66, 66);
}
.icon-B .path26:before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path27:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path28:before {
  content: "\e94e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path29:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path30:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(66, 66, 66);
}
.icon-B .path31:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path32:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path33:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path34:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path35:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(66, 66, 66);
}
.icon-B .path36:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path37:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path38:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path39:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-B .path40:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(66, 66, 66);
}
.icon-ViewDesktop .path1:before {
  content: "\e95b";
  color: rgb(0, 0, 0);
}
.icon-ViewDesktop .path2:before {
  content: "\e95c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ViewDesktop .path3:before {
  content: "\e95d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ViewDesktop .path4:before {
  content: "\e95e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ViewDesktop .path5:before {
  content: "\e95f";
  margin-left: -1em;
  color: rgb(66, 66, 66);
}
.icon-DocumentSASS:before {
  content: "\e960";
}
.icon-ArrowTrendingLines .path1:before {
  content: "\e961";
  color: rgb(0, 0, 0);
}
.icon-ArrowTrendingLines .path2:before {
  content: "\e962";
  margin-left: -1.0205078125em;
  color: rgb(66, 66, 66);
}
.icon-MoneySettings .path1:before {
  content: "\e963";
  color: rgb(0, 0, 0);
}
.icon-MoneySettings .path2:before {
  content: "\e964";
  margin-left: -1.0205078125em;
  color: rgb(66, 66, 66);
}
.icon-Shield .path1:before {
  content: "\e965";
  color: rgb(0, 0, 0);
}
.icon-Shield .path2:before {
  content: "\e966";
  margin-left: -1.0205078125em;
  color: rgb(66, 66, 66);
}
.icon-PersonStarburst .path1:before {
  content: "\e967";
  color: rgb(0, 0, 0);
}
.icon-PersonStarburst .path2:before {
  content: "\e968";
  margin-left: -1.0205078125em;
  color: rgb(66, 66, 66);
}
.icon-TargetArrow .path1:before {
  content: "\e969";
  color: rgb(0, 0, 0);
}
.icon-TargetArrow .path2:before {
  content: "\e96a";
  margin-left: -1em;
  color: rgb(66, 66, 66);
}
.icon-X:before {
  content: "\e900";
}
.icon-Facebook:before {
  content: "\e901";
}
.icon-Instagram:before {
  content: "\e902";
}
.icon-LinkedIn:before {
  content: "\e903";
}
