.desktopNav {
    display: flex;
    align-items: center;
}

.navList {
    display: flex;
    gap: 30px;
    list-style: none;
    margin: 0;
    padding: 0;
}

@media (max-width: 1439px) {
    .desktopNav {
        display: none;
    }
}